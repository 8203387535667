<template>
    <b-card title="Currency Type">
        <b-form>
            <b-row>
                <b-col md="2">
                    <b-form-group label="Currency Type Name">
                        <b-form-input v-model="currencyTypeName"
                            placeholder="Please Enter Currency Type Name"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col md="2">
                    <b-form-group label="Currency Symbol">
                        <b-form-input v-model="currencySymbol"
                            placeholder="Please Enter Currency Symbol"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col md="2">
                    <b-form-group label="USD Equivalent">
                        <b-form-input v-model="usdEquivalent" placeholder="Please Enter USD Equivalent"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col md="2">
                    <b-form-checkbox class="mt-2" v-model="isActive">IsActive</b-form-checkbox>
                </b-col>
                <b-col md="2">
                    <b-form-checkbox class="mt-2" v-model="isDefaultCurrency">Default Currency</b-form-checkbox>
                </b-col>
                <b-col md="2" class="text-center">
                    <b-button block variant="primary" class="mt-2 mr-2" @click="addUpdateCurrencyType">{{
                            addUpdateButton
                    }}
                    </b-button>
                </b-col>
            </b-row>
            <hr>
            <div>
                <!-- search input -->
                <div class="custom-search d-flex justify-content-end">
                    <b-form-group>
                        <div class="d-flex align-items-center">
                            <label class="mr-1">Search</label>
                            <b-form-input v-model="searchTerm" placeholder="Search" type="text"
                                class="d-inline-block" />
                        </div>
                    </b-form-group>
                </div>

                <!-- table -->
                <vue-good-table :line-numbers="true" :columns="columns" :rows="rows" :rtl="direction" :search-options="{
                    enabled: true,
                    externalQuery: searchTerm
                }" :select-options="{
    enabled: false,
    selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
    selectionInfoClass: 'custom-class',
    selectionText: 'rows selected',
    clearSelectionText: 'clear',
    disableSelectInfo: true, // disable the select info panel on top
    selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
}" :pagination-options="{
    enabled: true,
    perPage: pageLength
}">
                    <template slot="table-row" slot-scope="props">
                        <!-- Column: Name -->
                        <span v-if="props.column.field === 'status'" class="text-nowrap">
                            <span v-if="props.row.status === true" class="text-nowrap">
                                <feather-icon icon="CheckIcon" class="isActive" />
                            </span>
                            <span v-if="props.row.status === false" class="text-nowrap">
                                <feather-icon icon="XIcon" class="isInActive" />
                            </span>
                        </span>

                        <!-- Column: Name -->
                        <span v-else-if="props.column.field === 'defaultCurrency'" class="text-nowrap">
                            <span v-if="props.row.defaultCurrency === true" class="text-nowrap">
                                <feather-icon icon="CheckIcon" class="isActive" />
                            </span>
                            <span v-if="props.row.defaultCurrency === false" class="text-nowrap">
                                <feather-icon icon="XIcon" class="isInActive" />
                            </span>
                        </span>

                        <!-- Column: Name -->
                        <span v-else-if="props.column.field === 'lastUpdated'" class="text-nowrap">
                            <span class="text-nowrap">
                                {{ convertTimeStampToDate(props.row.lastUpdated) }}
                            </span>
                        </span>

                        <!-- Column: Name -->
                        <span v-else-if="props.column.field === 'lastUpdatedBy'" class="text-nowrap">
                            <span class="text-nowrap">
                                {{ props.row.lastUpdatedBy.fullName }}
                            </span><br>
                            <span class="text-nowrap">
                                [ {{ props.row.lastUpdatedBy.mobileNo }} ]
                            </span>
                        </span>

                        <!-- Column: Action -->
                        <span v-else-if="props.column.field === 'action'">
                            <span>
                                <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                                    <template v-slot:button-content>
                                        <feather-icon icon="MoreVerticalIcon" size="16"
                                            class="text-body align-middle mr-25" />
                                    </template>
                                    <b-dropdown-item
                                        @click="editButton(props.row.id, props.row.name, props.row.symbol, props.row.usdEquivalent, props.row.status, props.row.isDefaultCurrency)">
                                        <feather-icon icon="Edit2Icon" class="mr-50" />
                                        <span>Edit</span>
                                    </b-dropdown-item>
                                    <b-dropdown-item @click="deleteCurrencyType(props.row.id)">
                                        <feather-icon icon="TrashIcon" class="mr-50" />
                                        <span>Delete</span>
                                    </b-dropdown-item>
                                </b-dropdown>
                            </span>
                        </span>

                        <!-- Column: Common -->
                        <span v-else>
                            {{ props.formattedRow[props.column.field] }}
                        </span>
                    </template>

                    <!-- pagination -->
                    <template slot="pagination-bottom" slot-scope="props">
                        <div class="d-flex justify-content-between flex-wrap">
                            <div class="d-flex align-items-center mb-0 mt-1">
                                <span class="text-nowrap ">
                                    Showing 1 to
                                </span>
                                <b-form-select v-model="pageLength" :options="['10', '20', '50','100']" class="mx-1"
                                    @input="(value) => props.perPageChanged({ currentPerPage: value })" />
                                <span class="text-nowrap"> of {{ props.total }} entries </span>
                            </div>
                            <div>
                                <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number
                                    last-number align="right" prev-class="prev-item" next-class="next-item"
                                    class="mt-1 mb-0" @input="(value) => props.pageChanged({ currentPage: value })">
                                    <template #prev-text>
                                        <feather-icon icon="ChevronLeftIcon" size="18" />
                                    </template>
                                    <template #next-text>
                                        <feather-icon icon="ChevronRightIcon" size="18" />
                                    </template>
                                </b-pagination>
                            </div>
                        </div>
                    </template>
                </vue-good-table>
            </div>
        </b-form>
    </b-card>
</template>
  
<script>
import {
    BAvatar, BCard, BRow, BCol, BForm, BButton, BBadge, BFormCheckbox, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import api from '@/store/api'
import moment from 'moment'

export default {
    components: {
        VueGoodTable,
        BAvatar,
        BBadge,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BDropdown, BFormCheckbox,
        BDropdownItem, BCard, BRow, BCol, BForm, BButton
    },

    data() {
        return {
            addUpdateButton: 'Add',
            currencyTypeName: '',
            currencySymbol: '',
            usdEquivalent: '',
            isActive: false,
            id: 0,
            pageLength: 10,
            dir: false,
            columns: [
                {
                    label: 'Currency Type Name',
                    field: 'name',
                },
                {
                    label: 'Currency Symbol',
                    field: 'symbol',
                },
                {
                    label: 'USD Equivalent',
                    field: 'usdEquivalent',
                },
                {
                    label: 'Status',
                    field: 'status',
                },
                {
                    label: 'Default Currency',
                    field: 'defaultCurrency',
                },
                {
                    label: 'Last Updated By',
                    field: 'lastUpdatedBy',
                },
                {
                    label: 'Last Updated',
                    field: 'lastUpdated',
                },
                {
                    label: 'Action',
                    field: 'action',
                },
            ],
            rows: [],
            searchTerm: '',
            isDefaultCurrency:false,

        }
    },
    methods: {
        convertTimeStampToDate(value) {
            if (value) {
                return moment(value).format('DD/MM/YYYY hh:mm');
            }else{
                return '';
            }
        },
        getCurrencyType() {
            this.id = 0;
            this.currencyTypeName = '';
            this.currencySymbol = '';
            this.usdEquivalent = '';
            this.isActive = false;
            this.addUpdateButton = "Add";
            let self = this;

            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/masters/currencys', data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));
                    self.rows = response.data;
                })
                .catch(function (error) {
                    console.log(error);

                });
        },

        addUpdateCurrencyType() {
            let self = this;
            if (!(self.currencyTypeName == '') && !(self.currencySymbol == '') && !(self.usdEquivalent == '')) {
                if (this.addUpdateButton == 'Add') {
                    var axios = require('axios');
                    var data = JSON.stringify(
                        {
                            "name": this.currencyTypeName, "symbol": this.currencySymbol,
                            "usdEquivalent": this.usdEquivalent, "status": this.isActive,
                            "lastUpdatedBy": {
                                'id': this.$store.state.userDetails.id,
                                'fullName': this.$store.state.userDetails.namePrefix + ' ' + this.$store.state.userDetails.name,
                                'mobileNo': this.$store.state.userDetails.mobileNo
                            },
                            "defaultCurrency":this.isDefaultCurrency
                        });
                    axios(api.getApi('Post', '/masters/currencys', data))
                        .then(function (response) {
                            //console.log(JSON.stringify(response.data));
                            self.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: `Currency Type Added Successfully!`,
                                    icon: 'SaveIcon',
                                    variant: 'success',
                                },
                            })
                            self.getCurrencyType();
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                } else {
                    let self = this;
                    var axios = require('axios');
                    var data = JSON.stringify({
                        "name": this.currencyTypeName, "symbol": this.currencySymbol,
                        "usdEquivalent": this.usdEquivalent, "status": this.isActive,
                        "lastUpdatedBy": {
                            'id': this.$store.state.userDetails.id,
                            'fullName': this.$store.state.userDetails.namePrefix + ' ' + this.$store.state.userDetails.name,
                            'mobileNo': this.$store.state.userDetails.mobileNo
                        },
                        "defaultCurrency":this.isDefaultCurrency
                    });
                    axios(api.getApi('put', '/masters/currencys/' + this.id, data))
                        .then(function (response) {
                            //console.log(JSON.stringify(response.data));
                            self.getCurrencyType();
                            self.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: `Currency Type Updated Successfully!`,
                                    icon: 'SaveIcon',
                                    variant: 'success',
                                },
                            })

                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                }
            } else {
                this.$swal({
                    title: '',
                    text: "Please Enter Add Fields Details.",
                    icon: 'error',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                })
            }


        },

        deleteCurrencyType(id) {
            let self = this;
            this.$swal({
                title: '',
                text: "Are You Sure You Want To Delete!",
                icon: 'error',
                customClass: {
                    confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                    var axios = require('axios');
                    var data = '';
                    axios(api.getApi('delete', '/masters/currencys/' + id, data))
                        .then(function (response) {
                            //console.log(JSON.stringify(response.data));
                            self.getCurrencyType();
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                }
            })
        },

        editButton(id, name, symbol, usdEquivalent, isActive,isDefaultCurrency) {
            this.id = id;
            this.addUpdateButton = 'Update';
            this.currencyTypeName = name;
            this.currencySymbol = symbol;
            this.usdEquivalent = usdEquivalent;
            this.isActive = isActive;
            this.isDefaultCurrency=isDefaultCurrency

        }
    },
    mounted() {

    },
    computed: {

    },
    created() {
        let self = this;
        self.getCurrencyType();
    },
}
</script>
<style lang="css">
@import '~vue-good-table/dist/vue-good-table.css';
</style>
<style>
.isActive {
    color: green;
    width: 26px;
    height: 26px;
}

.isInActive {
    color: red;
    width: 26px;
    height: 26px;
}
</style>
   